<!--
 * @Description: 咨询动态
 * @Author: 李海帆
 * @Date: 2021/3/11 15:10
-->
<template>
  <div class="section">
    <div class="section-title">
      <div class="main-title">
        <p>资讯动态</p>
      </div>
      <div class="sub-title">
      </div>
    </div>
    <div class="live-section">
      <template v-if="newsList.length > 0">
        <div class="live-fl">
          <div class="mask-cover"/>
          <div class="live-fl-upper">
          </div>
          <div class="live-fl-lower">
            <p class="lecturer-name">
              {{ activeData.publishTime | formatDate}}
            </p>
            <nuxt-link
                :to="`/news/news-info?newsId=${activeData.newsId}`">
              {{ activeData.newsTitle }}
              <Icon
                  type="ios-arrow-forward"
                  size="16"
              />
            </nuxt-link>
          </div>
        </div>
        <div class="live-fr scroll-bar-style">
          <ul class="live-list">
            <li
                v-for="(item,index) in newsList"
                :key="item.resourceId"
                :class="{active: index === activeIndex}"
            >
              <div
                  class="live-item"
                  @mouseenter="enterLive(index)"
                  @click="executeClick(item)"
              >
                <div class="live-time">
                  {{ item.publishTime | formatDate }}
                </div>
                <div class="live-name">
                  {{ item.newsTitle }}
                </div>
                <div class="live-state"></div>
              </div>
            </li>
          </ul>
        </div>
      </template>
      <Spin
          v-if="isLoading"
          fix
      >
        <Icon
            type="ios-loading"
            size="18"
            class="demo-spin-icon-load"
        />
      </Spin>
    </div>
  </div>
</template>

<script>
import {newsApi} from '@/api/news';

export default {
  name: 'NewsIndex',
  components: {},
  filters: {
    formatDate(val) {
      return val ? val.substr(0, 10) : '';
    }
  },
  props: {},
  data() {
    return {
      isLoading: false,
      activeIndex: 0,
      activeData: {},
      newsList: [],
      pageNum: 1,
      pageSize: 100
    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
    this.queryNews(1000087);
  },
  methods: {

    // 查询资讯
    async queryNews(typeId) {
      try {
        this.$Spin.show();
        const result = await newsApi.queryNewsList({
          newsTypeId: typeId,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        });
        this.newsList.push(...result.data.list);
        if (this.newsList.length > 0) {
          this.activeData = this.newsList[0];
        }
      } catch (e) {
        console.log(e);
        this.$smartSentry.captureException(e);
      } finally {
        this.$Spin.hide();
      }
    },

    // 鼠标移入列表项
    enterLive(index) {
      if (index != this.activeIndex) {
        this.activeIndex = index;
        this.activeData = this.newsList[index];
      }
    },

    // 点击某个列表项或左侧进入直播按钮
    executeClick(item) {
      this.$router.push({
        path: `/news/news-info?newsId=${item.newsId}`
      });
    }

  }
};
</script>
<style lang='less' scoped>
@import "../section.less";

.live-section {
  display: flex;
  height: 288px;
  background-color: #fff;
  position: relative;

  .live-fl {
    width: 512px;
    height: 288px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: url("~@/assets/image/index/news-bg.png") center/512px no-repeat;
    overflow: hidden;
    border-radius: 4px;

    .lecturer-pic {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      margin: 0 auto;
      width: 340px;
    }

    .mask-cover {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
    }

    .live-fl-upper {
      flex: 1;
      padding: 70px 10px 0;
      position: relative;
      z-index: 3;

      .current-live-title {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 20px;
      }

      .current-live-button {
        width: 106px;
        height: 40px;
        line-height: 38px;
        text-align: center;
        border: 1px solid #707070;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #ffffff;
        margin: 0 auto;
        cursor: pointer;
      }
    }

    .live-fl-lower {
      height: 54px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.7);
      position: relative;
      z-index: 3;

      .lecturer-avatar {
        display: block;
        background-color: #fff;
      }

      .lecturer-name {
        flex: 1;
        color: #fff;
        overflow: hidden;
        margin: 0 8px;
      }

      a {
        display: block;
        color: #fff;
      }
    }
  }

  .live-fr {
    flex: 1;
    height: 288px;
    overflow: hidden;
    overflow-y: auto;

    .live-list {
      position: relative;

      li {
        height: 48px;
        position: relative;
        padding: 10px 20px 10px 30px;

        &.active {
          &::after {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 4px;
            border-radius: 2px;
            background-color: @main-color;
            content: "";
          }

          .live-item {
            background: url("~@/assets/image/index/news-state-icon-active.png") 2px center no-repeat;
            background-size: 8px;
            &::before{
              width: 0;
            }
          }

          .live-state {
            background-image: url("~@/assets/image/index/news-list-go-avtivity.png");
          }
        }

        .live-item {
          height: 28px;
          padding-left: 30px;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;

          &::before {
            content: '';
            width: 8px;
            height: 8px;
            background: #cccccc;
            border-radius: 50%;
            position: absolute;
            left: 2px;
            top: 10px;
            bottom: 0;
          }

          .live-time {
            width: 170px;
            color: #999;
          }

          .live-name {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
          }

          .live-lecturer {
            width: 76px;
            color: #999;
          }
        }

        .live-state {
          width: 20px;
          height: 20px;
          background: url("~@/assets/image/index/news-list-go.png") center/100% no-repeat;
        }
      }
    }
  }
}
</style>
