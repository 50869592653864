/*
 * @Description: 购物车
 * @version:
 * @Author: 李云飞
 * @Date: 2020-12-11 11:57:41
 * @LastEditors: hanyu
 * @LastEditTime: 2021-01-13 11:02:25
 */

import { postAxios, getAxios } from '@/lib/http';
export const shoppingCartApi = {

  // 添加商品到购物车 @author liyingwu
  addGoodToCart (data) {
    return postAxios(`/pc/goods/cart/add`,data);
  },

  // 批量删除购物车商品 @author lianmin
  batchDeleteCartGoods (data) {
    return postAxios('/pc/goods/cart/batch/delete', data);
  },

  // 根据购物车ID删除记录 @author liyingwu
  deleteCartGoods (shoppingCartId) {
    return getAxios(`/pc/goods/cart/delete/${shoppingCartId}`);
  },

  // 查询购物车商品数量(不计算重复) @author lianmin
  getCartGoodsNumber () {
    return getAxios('/pc/goods/cart/goodsNumber');
  },

  // 查询分校或学员购物车商品 @author liyingwu
  getUserCartGoods () {
    return getAxios('/pc/goods/cart/query');
  },

  // 更新购物车商品数量 @author liyingwu
  updateCartGoodsNum (data) {
    const { shoppingCartId, goodsNum } = data;
    return getAxios(`/pc/goods/cart/update/${shoppingCartId}/${goodsNum}`);
  }
};
