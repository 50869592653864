<template>
  <Modal v-model="showModal" width="1000" footer-hide>
    <p slot="header" class="header">
      <span>2024年执业药师继续教育相关工作安排</span>
    </p>
    <div class="content">
      <p>
        <span>一、继续教育时间：</span>2024年3月15日——2024年11月30日24时
      </p>
      <p>
        <span>　　报名时间：</span>2024年3月15日——2024年11月15日
      </p>
      <p>
        <span>二、继续教育对象：</span>全省取得执业药师资格人员
      </p>
      <p>
        <span>三、继续教育学时：</span>全年需完成90学时的继续教育，其中：专业科目60学时，公共科目30学时
      </p>
      <p>
        <span>四、继续教育收费标准：</span>200元/年•人（执行年度：2024年、2023年、2022年、2021年）
      </p>
      <p>
        <span>五、其他要求：</span>参加继续教育时，请准确填写个人信息及资格证信息，便于学时管理
      </p>
    </div>
  </Modal>
</template>
<script>
export default {
  name: 'SystemCloseModal',
  components: {},
  filters: {},
  mixins: [],
  props: {},
  data () {
    return {
      showModal: false
    };
  },
  computed: {
    type () {
      return process.env.TYPE;
    }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.header {
  color: #1c2634;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px;

  > img {
  }

  > p {
    font-size: 18px;
    font-weight: 500;
    line-height: 34px;
  }

  > p > span {
      font-weight: 600;
  }

}
</style>
