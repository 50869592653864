/*
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-23 11:45:11
 * @LastEditors: 李云飞
 * @LastEditTime: 2020-09-23 14:45:39
 */
export const arrData = [
  {
    userMsg: '听了这个课，才觉得这个讲的真好，分析的真透彻，老师讲课风趣声音好听，易懂。认真负责，一听就懂，声音好听，有磁性。五星好评。',
    userName: 'Tan******ny',
    userMajor: '临床执业'
  },
  {
    userMsg: '老师讲的课程通俗易懂，重点突出，知识点全面，讲的很透彻，容易理解和接受，需要注意的地方老师都有提醒，老师的思路清晰，整体感觉非常棒，点个赞，希望云南省执业药师、药学专业人员继续教育平台越来越好，希望报名的都过，考试的都会。',
    userName: '134******80',
    userMajor: '中医执业'
  },
  {
    userMsg: '上课时能驾驭课堂，处理好突发事件，营造的课堂气氛和谐融洽，上课使用的教学方法易于掌握。谢谢大爱无私奉献的老师！',
    userName: 'Lcx*******54',
    userMajor: '中西医执业'
  },
  {
    userMsg: '云南省执业药师、药学专业人员继续教育平台的老师是我遇到过最好的老师！讲课生动形象通俗易懂！再难的问题都是小意思，复杂的问题变得简单，题也能做对了，希望云南省执业药师、药学专业人员继续教育平台越做越大加油！',
    userName: 'Ce*****01',
    userMajor: '口腔执业'
  },
  {
    userMsg: '以前不喜欢自己的专业，自从听了尤老师的课，已经喜欢上自己的专业了。越听越想听，老师讲的条理清晰易懂，易记住，太太喜欢了，太棒啦！尤老师尤神尤神尤神尤神！没有哪个老师可以比的过他！',
    userName: 'Zr***22',
    userMajor: '执业护士'
  },
  {
    userMsg: '老师深入浅出，讲的通俗易懂，课件准备的精心，让人很容易抓住重点。坚持的跟着学下来。会少走很多弯路，在上课过程中，老师有意控制语速，让大家尽可能吸收每个知识点，迅速将知识点消化吸收，非常感谢云南省执业药师、药学专业人员继续教育平台教育平台，良心教育～',
    userName: 'a1****95',
    userMajor: '执业西药师'
  },
  {
    userMsg: '还会编顺口溜，还会比喻，非常生动而形象，让你很容易理解和记忆。简明扼要，直击重点！这是我听过的讲的好的课。发自内心的好！赞',
    userName: 'Bj******4',
    userMajor: '执业中药师'
  },
  {
    userMsg: '课程真心地不错，第二轮复习心中有底了，坚持到底就是胜利！学习是一种心态，好的老师就是这个心态的按钮，开始了就是成功！加油必过。',
    userName: '你****蜜',
    userMajor: '临床助理'
  },
  {
    userMsg: '非常的好啊！十分喜欢武老师的课！生动形象的讲解！又容易听懂。还都是重点，真的非常的nice！讲的真的是太好了！非常的棒！',
    userName: 'Xy*****35',
    userMajor: '中西医助理'
  },
  {
    userMsg: '老师讲课非常好，经常有直播与学生互动，重点突出，解决我们的难点和头痛的问题，再次感谢云南省执业药师、药学专业人员继续教育平台',
    userName: '15******77',
    userMajor: '健康管理师'
  },
  {
    userMsg: '云南省执业药师、药学专业人员继续教育平台的老师们不仅在课堂上为我们讲解知识，课后有什么疑问也会有专门的教辅老师为我们一一解答，耐心又负责，让我对考试更有信心了！',
    userName: '138******68',
    userMajor: '乡村全科'
  },
  {
    userMsg: '非常感谢在高端班的那段时间，不仅是我们在高强度的集中学习，还有那么多老师在为我们辛苦的做后勤工作，特别谢谢他们。',
    userName: '异****尘',
    userMajor: '临床执业'
  }
]
