/*
 * @Description:
 * @Author: hanyu
 * @Date: 2020-11-25 08:34:16
 * @LastEditTime: 2020-12-16 17:24:15
 * @LastEditors: hanyu
 */
import { postAxios, getAxios } from '@/lib/http';

// 图书商城

export const goodsApi = {
  // 查询PC首页商品一级专业列表 by lianmin
  queryHomeMajorList: () => {
    return getAxios('/pc/goods/home/major');
  },
  //  查询PC首页推荐商品列表 lianmin
  queryHomeGoodsList: (data) => {
    return postAxios('/pc/goods/home/query', data);
  },
  // PC - 批量查询订单商品列表 by lianmin
  getOrderGoodsList: (data) => {
    return postAxios('/pc/goods/order/query', data);
  }
};
