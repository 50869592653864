<!--
 * @Description: 免费视频
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-21 17:13:42
 * @LastEditors: hanyu
 * @LastEditTime: 2021-03-11 11:39:19
-->
<template>
  <div class="section">
    <div class="section-title">
      <div class="main-title">
        <p>药师课程  精彩纷呈</p>
      </div>
    </div>
    <div class="section-free">
      <ul class="free-list">
        <li v-for="item in freeList" :key="item.courseId">
          <nuxt-link :to="`/course/detail?courseAttribute=${item.courseAttributeType}&courseId=${item.courseId}`">
            <div class="course-cover">
              <img v-if="item.lecturer" :class="item.lecturer.lecturerDefaultPhoto==null ? 'lecturer-img-default' : 'lecturer-img'"
                :src="item.lecturer.lecturerDefaultPhoto || defaultHeader">
            </div>
            <div class="course-lower">
              <div>
                <div class="course-name text-two-row-hide">
                  {{ item.courseName }}
                </div>
                <div class="major-lecturer">
                  <div class="m-tag one-row">
                    {{ item.majorName }}
                  </div>
                  <p class="lecturer-name">
                    {{ item.lecturer ? item.lecturer.lecturerName : '' }}
                  </p>
                </div>
              </div>
              <div class="course-info">
                <p class="free-text">
<!--                  免费-->
                </p>
                <p class="study-num">
                  {{ item.buyNum }}人在学习
                </p>
              </div>
            </div>
          </nuxt-link>
        </li>
      </ul>
      <Spin v-if="isLoading" fix>
        <Icon type="ios-loading" size="18" class="demo-spin-icon-load" />
      </Spin>
    </div>
  </div>
</template>

<script>
import { courseApi } from '@/api/course';
import { COURSE_SALE_TYPE_ENUM } from '@/constant/course';
import defaultHeader from '@/assets/image/index/default-header.png';
import SectionMajor from '../section-major';
export default {
  name: 'FreeSection',
  components: {
    SectionMajor
  },
  filters: {},
  props: {
    majorList: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  data () {
    return {
      defaultHeader,
      COURSE_SALE_TYPE_ENUM,
      isLoading: false,
      freeList: [],
      majorId: null
    };
  },
  computed: {},
  watch: {
  },
  created () {
  },
  mounted () {
    this.majorId = this.majorList[0].majorId;
    this.queryFreeList();
  },
  methods: {

    // 查询免费课程
    async queryFreeList () {
      try {
        this.isLoading = true;
        const data = { majorId: this.majorId, showNum: 5 };
        const result = await courseApi.queryFreeList(data);
        this.freeList = result.data;
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },

    // 选择专业改变
    changeMajor (params) {
      this.majorId = params.majorId;
      this.queryFreeList();
    }

  }
};
</script>
<style lang='less' scoped>
@import "../section.less";
.section-free {
  padding: 15px 12px 15px;
  background-color: #fff;
  position: relative;
  .free-list {
    display: flex;
    margin: 0 -7px;
    li {
      width: 224px;
      height: 260px;
      margin: 0 7px;
      > a {
        transition: transform 0.2s;
        margin-top: 5px;
        display: block;
        width: 224px;
        height: 255px;
        background-color: #fff;
        border-radius: 4px;
        overflow: hidden;
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0px 6px 10px 0px rgba(153, 153, 153, 0.16);
        }
        .course-cover {
          width: 224px;
          height: 126px;
          background-color: #ebe6eb;
          overflow: hidden;

          .lecturer-img-default {
            display: block;
            //width: 180px;
            height: 126px;
            margin: 0 auto;
          }

          .lecturer-img {
            display: block;
            width: 180px;
            margin: 0 auto;
          }
        }
        .course-lower {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 129px;
          padding: 10px 10px 18px;
          border: 1px solid #ededed;
          border-top: none;
          border-radius: 0 0 4px 4px;
          .course-name {
            color: #333;
            font-size: 16px;
            margin-bottom: 3px;
            height: 50px;
          }
          .major-lecturer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 12px;
            margin-top: 6px;
            line-height: 20px;
            .m-tag{
              border-radius: 2px;
              align-items: center;
              justify-content: center;
              color: #13619f;
              font-size: 12px;
              width: 154px;
            }
            .lecturer-name {
              flex-shrink: 0;
              padding-left: 4px;
              font-size: 12px;
              color: #999;
            }
          }
          .course-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .free-text {
              height: 16px;
              line-height: 16px;
              font-size: 16px;
              color: #ff572a;
              font-weight: 600;
            }
            .study-num {
              padding-left: 20px;
              height: 16px;
              line-height: 16px;
              font-size: 12px;
              color: #999;
              background: url("~@/assets/image/index/study-num.png") left center
                no-repeat;
            }
          }
        }
      }
    }
  }
}
</style>
