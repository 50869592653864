<!--
 * @Description:
 * @version:
 * @Author: 李云飞
 * @Date: 2020-09-21 17:17:05
 * @LastEditors: hanyu
 * @LastEditTime: 2021-01-13 11:14:29
-->
<template>
  <div class="section">
    <div class="section-title">
      <div class="main-title">
        <p>热门图书</p>
      </div>
      <div class="sub-title">
        资深专家精心编撰 涵盖新大纲主要考点
      </div>
    </div>
    <div class="section-book">
      <SectionMajor :major-list="majorList"
        more-link="/book"
        @changeMajor="changeMajor" />
      <!-- 详情预览 -->
      <div v-if="previewData"
        class="preview">
        <div class="book-img">
          <img :src="previewData.coverPic">
        </div>
        <div class="book-info">
          <div class="book-name">
            {{ previewData.goodsName }}
          </div>
          <div class="book-desc">
            <p>{{ previewData.goodsIntro }}</p>
          </div>
          <div class="book-price">
            <div class="present-price">
              现价：<span>&yen; </span><span class="amount">{{ previewData.officialPrice }}</span>
            </div>
            <div v-if="previewData.crossPrice"
              class="original-price">
              原价：<span>&yen; {{ previewData.crossPrice }}</span>
            </div>
          </div>
          <div class="book-sold">
            已售：<span>{{ previewData.sales }}</span>本
          </div>
          <div class="info-bottom">
            <div class="book-promise">
              <div class="service-promise">
                服务承诺:
              </div>
              <div class="promise-list">
                <div class="quality">
                  正品保证
                </div>
                <div class="fast">
                  极速发货
                </div>
                <div class="refund">
                  七天无理由退货
                </div>
              </div>
              <div class="button-group">
                <div class="button-item add-cart"
                  @click="addCard(previewData.goodsId)">
                  加入购物车
                </div>
                <div class="button-item now-buy"
                  @click="toBuy(previewData.goodsId)">
                  立即购买
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 列表 -->
      <ul class="book-list">
        <li v-for="item in bookList"
          :key="item.id"
          class="book-item"
          @click="checkBook(item)">
          <img class="book-item-img"
            :src="item.coverPic">
          <p class="book-item-name text-two-row-hide">
            {{ item.goodsName }}
          </p>
        </li>
      </ul>
    </div>

    <Spin v-if="isLoading"
      fix>
    </Spin>
  </div>
</template>

<script>
import { goodsApi } from '@/api/goods';
import { shoppingCartApi } from '@/api/shopping-cart';
import SectionMajor from '../section-major';
export default {
  name: 'BookSection',
  components: {
    SectionMajor
  },
  filters: {},
  props: {},
  data () {
    return {
      isLoading: false,
      activeMajorIndex: 0,
      majorList: [],
      bookList: [],
      previewData: null
    };
  },
  computed: {
  },
  watch: {
    bookList (newVal) {
      this.previewData = newVal[0];
    }
  },
  created () {
  },
  mounted () {
    // 获取专业列表
    this.queryMajorList();
  },
  methods: {
    // 选择专业改变
    changeMajor (data) {
      this.queryBookListByMajorId(data.majorId);
    },

    // 选择图书
    checkBook (item) {
      this.previewData = item;
    },

    // 查询专业列表
    async queryMajorList () {
      try {
        this.isLoading = true;
        const result = await goodsApi.queryHomeMajorList();
        this.majorList = result.data;
        // 获取当前专业图书
        if (this.majorList[0]) {
          this.queryBookListByMajorId(this.majorList[0].majorId);
        }
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },

    // 根据专业id 查询推荐的商品列表
    async queryBookListByMajorId (majorId) {
      if (!majorId) {
        this.bookList = [];
        return;
      }
      try {
        this.isLoading = true;
        const param = {
          majorId,
          pageNum: 1,
          pageSize: 7,
          sortItemList: [
            {
              sort: false,
              sortItem: 'recommend_flag'
            },
            {
              sort: false,
              sortItem: 'create_time'
            }
          ]
        };
        const result = await goodsApi.queryHomeGoodsList(param);
        this.bookList = result.data.list;
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },

    // 点击加入购物车
    async addCard (goodsId) {
      if (!this.$store.state.user.userInfo) {
        const params = { isShow: true };
        this.$store.commit('user/changeAccountModal', params);
        return;
      };
      try {
        this.isLoading = true;
        await shoppingCartApi.addGoodToCart({goodsId,goodsNum: 1});
        this.$Message.success('已加入购物车');
        this.$store.commit('user/changeCartFrequency');
      } catch (err) {
        this.$smartSentry.captureException(err);
      } finally {
        this.isLoading = false;
      }
    },

    // 点击立即购买
    toBuy (goodsId) {
      if (!this.previewData.shelvesNumber) {
        this.$Message.error('该图书暂无库存！');
        return;
      }
      if (!this.$store.state.user.userInfo) {
        const params = { isShow: true };
        this.$store.commit('user/changeAccountModal', params);
        return;
      };
      const goodsData = [{ goodsId, buyNum: 1 }];
      const storageData = JSON.stringify(goodsData);
      window.sessionStorage.setItem('orderSessionStorage', storageData);
      this.$router.push({
        path: '/order/check-shop-order'
      });
    }
  }
};
</script>
<style lang='less' scoped>
@import "../section.less";
@import "./index.less";
</style>
