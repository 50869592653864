<template>
  <div class="course-content" v-if="!lodash.isEmpty(allCourseList)"> 
    <div class="category-content"
      v-for="item in allCourseList"
      :key="item.categoryId">
      <div class="top-row">
        <div class="category-name">{{item.categoryName}}</div>
        <a @click="toAllCourse(item.categoryId)">全部课程></a>
      </div>
      <div class="course-row">
        <div class="course-item"
          v-for="(courseItem, courseIndex) in item.courseList"
          :key="courseItem.courseId"
          :class="{'last-course': courseIndex === 2}"
          @click="toCourseDetail(courseItem.courseId)">
          <img class="course-banner" :src="courseItem.coverImg"/>
          <div class="course-bottom">
            <div class="course-name two-row">{{courseItem.courseName}}</div>
            <div class="check-btn">查看详情</div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    allCourseList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  methods: {
    toAllCourse(categoryId) {
      if (this.lodash.isEmpty(this.userInfo)) {
        this.$emit('login');
        return;
      }
      this.$pcRouter.push({
        path: 'center/sign',
        query: {
          categoryId: categoryId
        }
      })
    },
    toCourseDetail(courseId) {
      if (this.lodash.isEmpty(this.userInfo)) {
        this.$emit('login');
        return;
      }
      this.$pcRouter.push({
        path: 'center/course-detail',
        query: {
          courseClassId: courseId
        }
      })
    }
   }
}
</script>
<style scoped lang="less">
.course-content {
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .category-content {
    width: calc(50% - 10px);
    margin-top: 24px;
    .top-row {
      width: 100%;
      height: 33px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .category-name {
        height: 33px;
        line-height: 33px;
        font-size: 24px;
        font-weight: 700;
        color: #1c2634;
        position: relative;
        padding-left: 21px;
        margin-bottom: 18px;
        &::before {
          content: '';
          width: 8px;
          height: 8px;
          background: #13619f;
          border-radius: 50%;
          position: absolute;
          left: 0;
          top: 10px;
        }
        a {
          color: #13619F;
        }
      }
      
    }
    .course-row {
      width: 100%;
      margin-bottom: 18px;
      display: flex;
      .course-item {
        width: 188px;
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
        margin-right: 12px;
        cursor: pointer;
        .course-banner {
          width: 188px;
          height: 130px;
        }
        .course-bottom {
          width: 100%;
          padding: 10px 10px 15px 10px;
          background: #fff;
          border: 1px solid #ededed;
          border-top: none;
          .course-name {
            width: 100%;
            height: 44px;
            line-height: 22px;
            color: #333;
            margin-bottom: 19px;
          }
          .check-btn {
            width: 100%;
            text-align: right;
            height: 17px;
            line-height: 17px;
            font-size: 12px;
            color: #13619F;
            cursor: pointer;
          }
        }
      }
      .last-course {
        margin-right: 0;
      }
    }
  }
}
</style>