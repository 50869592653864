<template>
  <div class="des" :style="{'border':showBorder?'1px solid #ededed':''}">
    <div class="progress-box">
      <div v-for="(item,index) in learnProgress" :key="index" class="des-item">
        <div class="step">
          <img :src="item.url" />
          <p>{{ item.label }}</p>
        </div>
      </div>
    </div>

    <div v-if="showHelpFlag" class="help-box">
      <div v-for="(item,index) in docList" :key="index" class="help-item">
        <div class="step">
          <img :src="item.img" />
          <a :href="item.downloadUrl" target="_blank">{{ item.name }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LearnProcess',
  components: {},
  filters: {},
  mixins: [],
  props: {
    showBorder: {
      type: Boolean,
      default: true
    },
    showHelpFlag: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      learnProgress: [{
        label: '账号注册',
        url: '/images/index/learn-progress/step-1.png'
      }, {
        label: '登录系统',
        url: '/images/index/learn-progress/step-2.png'
      }, {
        label: '选课缴费',
        url: '/images/index/learn-progress/step-4.png'
      }, {
        label: '学习视频',
        url: '/images/index/learn-progress/step-5.png'
      }, {
        label: '参加考试',
        url: '/images/index/learn-progress/step-6.png'
      }, {
        label: '打印学分',
        url: '/images/index/learn-progress/step-7.png'
      }, {
        label: '申请开票',
        url: '/images/index/learn-progress/step-3.png'
      }],
      docList: [
        {
          name: 'PC电脑帮助文档',
          downloadUrl: process.env.TYPE == "ZHI_YE" ? '/云南药师继续教育PC电脑端使用手册.pdf' : '/药学专业技术人员继续教育PC电脑端使用手册.pdf',
          img: '/images/index/help-pc-icon.png'
        }
        // {
        //   name: '安卓/苹果手机端APP帮助文档',
        //   downloadUrl: process.env.TYPE == "ZHI_YE" ? '/云南药师继续教育APP端（安卓及苹果手机）使用手册.pdf' : '/药学专业技术人员继续教育APP端（安卓及苹果手机）使用手册.pdf',
        //   img: '/images/index/help-app-icon.png'
        // },
        // {
        //   name: '苹果手机APP安装授信帮助文档',
        //   downloadUrl: process.env.TYPE == "ZHI_YE" ? '/苹果手机IOS系统APP应用安装授信操作说明.pdf' : '/药学专业技术人员继续教育苹果手机IOS系统APP应用安装授信操作说明.pdf',
        //   img: '/images/index/help-ios-icon.png'
        // }
      ]
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {}
}
;
</script>
<style lang="less" scoped>
.des {
  width: 100%;
  height: 284px;
  background: #ffffff;
  border-radius: 5px;
}

.progress-box {
  height: 184px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.des-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  img {
    width: 80px;
    height: 80px;
  }

  p {
    margin-top: 18px;
    font-size: 16px;
    color: #1c2634;
  }

  &:after {
    content: '';
    position: absolute;
    width: 7px;
    height: 12px;
    background: url("/images/index/learn-progress/learn-progress-arrow.png") center/100% no-repeat;
    right: -20px;
    bottom: 4px;
  }

  &:last-child:after {
    display: none;
  }
}

.help-box {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .help-item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .step {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 22px;
      height: 22px;
    }

    a {
      color: #1c2634;
      font-size: 16px;
      color: #13619f;
      line-height: 26px;
      margin-left: 11px;
    }
  }

}
</style>
