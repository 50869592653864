<template>
  <Modal v-model="showModal" width="1000" footer-hide>
    <p slot="header" class="header">
      <span>报名流程</span>
    </p>
    <div class="content">
      <LearnProcess :show-border="false"></LearnProcess>
      <p>1.用户登录系统</p>
      <p>2.用户若已有账号，可用已有账号登录；若没有已有账号，则需要注册账号，并完善个人信息。</p>
      <p>3.个人信息完善后，进入报名缴费模块，选择对应需要报名学习的学年，并对该学年课程进行缴费。</p>
      <p>4.缴费完成后，可在线查看已缴费学年课程视频。</p>
      <p>5.课程视频均看完后，可以参与在线考试。</p>
      <p>6.在线考试通过后，整个学年的学习结束，可以申请在线打印学分证明。</p>
    </div>
  </Modal>
</template>
<script>
import LearnProcess from '@/components/learn-process';
export default {
  name: 'SignUpProcessModal',
  components: {
    LearnProcess
  },
  filters: {},
  mixins: [],
  props: {},
  data () {
    return {
      showModal: false,
      learnProgress: ['账号注册', '登录系统', '完善信息',  '学习视频','选课缴费', '参加考试', '打印学分']
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.header {
  color: #1c2634;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: column;

  /deep/.des {
    height: 180px;
  }

  > p {
    margin-left: 46px;
    font-size: 18px;
    font-weight: 500;
    line-height: 34px;
  }

}
</style>
