<template>
  <div class="section">
    <div class="section-title">
      <div class="main-title red">
        <p style="color: #f26158">
          {{ newsCategory.categoryName }}
        </p>
      </div>
    </div>
    <div class="section-government">
      <div v-for="item in newsList" :key="item.newsId" class="government-item"
           @click="goPage(`/news/news-info?newsId=${item.newsId}`)">
        <div class="item-header">
          {{ item.title }}
        </div>
        <div class="item-content">
          <div>
            <!--限制字数 64字-->
            <a class="content">{{ item.newsIntro }}</a>
            <a class="more">查看详情></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { newsApi } from '@/api/news';
import { NEWS_TYPE_ENUM } from '@/constant/common';

export default {
  name: '',
  components: {},
  filters: {},
  mixins: [],
  props: {},
  data () {
    return {
      NEWS_TYPE_ENUM,
      newsList: [],
      newsCategory: {}
    };
  },
  computed: {},
  watch: {},
  created () {
  },
  mounted () {
  },
  methods: {
    // 查询党政建设资讯
    async queryNewsList (newsCategory) {
      this.newsCategory = newsCategory;
      try {
        const data = {
          pageNum: 1,
          pageSize: 3,
          categoryId: newsCategory.categoryId,
          newsTypeId: NEWS_TYPE_ENUM.GOVERNMENT.value
        };
        const result = await newsApi.queryNewsList(data);
        this.newsList = result.data.list;
      } catch (e) {

      }
    },
    goPage (src) {
      window.open(src);
    }
  }
};
</script>
<style lang="less" scoped>
@import "../section.less";

.section-government {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .government-item {
    cursor: pointer;
    width: 387px;
    height: 183px;
    background: #fffbfb;
    border: 1px solid #fffbfb;
    border-radius: 5px;
    display: flex;
    flex-direction: column;

    &:hover {
      border-color: #f26158;
    }

    .item-header {
      flex-shrink: 0;
      padding: 0 17px;
      height: 79px;
      border-bottom: 1px solid rgba(242, 97, 88, 0.2);
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      text-align: left;
      color: #f26158;
      line-height: 27px;
    }

    .item-content {
      flex: 1;
      padding: 0 17px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      line-height: 24px;
      letter-spacing: 1px;

      .content {
        color: #666666;
      }

      .more {
        color: #f26158;
      }
    }
  }
}
</style>
