<template>
  <Modal v-model="showModal" width="1000" footer-hide>
    <p slot="header" class="header">
      <span>听课须知</span>
    </p>
    <div class="content">
      <p>从2020年起，云南省药师继续教育必须完成90课时（30学分）的学习，其中，公需科目30课时（10学分），专业科目60课时（20学分）。</p>
      <br />
      <p>
        公需及专业科目学习与考核：云南省执业药师及药学专业技术人员须统一在云南省执业药师、药学专业人员继续教育平台（https://ynzy.renminyixue.com/），完成不少于90课时的学习，并参加学习后的在线考核，以取得整个学年的学分及学分证明。
      </p>
      <br />
      <p>协会特别提醒：需在本省注册执业药师的，请根据自身实际情况合理安排学习和考核时间，以免错过。</p>
    </div>
    <!--<div v-if="type == 'YAO_XUE'" class="content">
      <p>从2020年起，云南省药学专业人员继续教育必须完成90课时（30学分）的学习，其中，公需科目30课时（10学分），专业科目60课时（20学分）。</p>
      <br />
      <p>
        公需及专业科目学习与考核：云南省药学专业技术人员须统一在云南省药学专业技术人员继续教育平台（https://ynyx.renminyixue.com/）或“云南药学继教”APP手机客户端，完成不少于90课时的学习，并参加学习后的在线考核，以取得整个学年的学分及学分证明。
      </p>
      <br />
      <p>云南省药师协会特别提醒：需在本省注册的药学专业技术人员，请根据自身实际情况合理安排学习和考核时间，以免错过。</p>
    </div>-->
  </Modal>
</template>
<script>
export default {
  name: 'NotesForLecturesModal',
  components: {},
  filters: {},
  mixins: [],
  props: {},
  data () {
    return {
      showModal: false
    };
  },
  computed: {
    type () {
      return process.env.TYPE;
    }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {}
};
</script>
<style lang="less" scoped>
.header {
  color: #1c2634;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 20px;

  > img {
  }

  > p {
    font-size: 18px;
    font-weight: 500;
    line-height: 34px;
  }

}
</style>
