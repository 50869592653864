<template>
  <div class="section">
    <div class="main-title">
      <p>{{ newsCategory.categoryName }}</p>
      <a class="more" :href="`/news/news-list?type=`">查看更多></a>
    </div>
    <div class="section-policy">
      <div class="news-item"
        v-for="item in newsList"
        :key="item.newsId">
        <div class="news-left">
          <b>{{ item.time }}</b>
          <p>{{ item.year }}</p>
        </div>
        <div class="news-content">
          <p class="one-row">
            <a :href="`/news/news-info?newsId=${item.newsId}`"
               :title="item.newsTitle"
               target="_blank">{{ item.title }}</a>
          </p>
          <p class="two-row">
            {{ item.newsIntro }}
          </p>
        </div>
      </div>
     
    </div>
  </div>
</template>
<script>
import { newsApi } from '@/api/news';
import { NEWS_TYPE_ENUM } from '@/constant/common';
export default {
  name: '',
  components: {},
  filters: {},
  mixins: [],
  props: {},
  data () {
    return {
      NEWS_TYPE_ENUM,
      leftNews: {},
      newsList: [],
      newsCategory: {}
    };
  },
  computed: {
    bgStyle(){
      if (this.leftNews.coverPic) {
        return {background:`url(${this.leftNews.coverPic}) no-repeat center`,backgroundSize: '540px;'};
      } else {
        return {};
      }
    }
  },
  watch: {},
  created () {
  },
  mounted () {
  },
  beforeCreate () {
  },
  beforeMount () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  activated () {
  },
  methods: {
    async queryNewsList (newsCategory, newsTypeId) {
      this.newsCategory = newsCategory;
      try {
        const data = {
          pageNum: 1,
          pageSize: 4,
          categoryId: newsCategory.categoryId,
          newsTypeId: newsTypeId
        };
        const result = await newsApi.queryNewsList(data);
        const list = result.data.list;
        if (!this.lodash.isEmpty(list)) {
          list.forEach((item) => {
            const createTime = item.createTime;
            if (createTime) {
              item.year = createTime.substring(0, 4);
              item.time = createTime.substring(5, 10);
            }
          });
        }
        this.newsList = result.data.list;
      } catch (e) {

      }
    }
  }
};
</script>
<style lang="less" scoped>
.section {
  width: 590px;
  height: 460px;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 18px 20px 20px 20px;
  .main-title {
    width: 100%;
    height: 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      height: 33px;
      line-height: 33px;
      font-size: 24px;
      font-weight: 700;
      color: #1c2634;
      position: relative;
      padding-left: 21px;
      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #13619f;
        position: absolute;
        top: 10px;
        left: 0;
      }
    }
    a {
      height: 20px;
      line-height: 20px;
      color: #13619f;
    }
  }
  .section-policy {
    width: 100%;
    margin-top: 12px;
    border-top: 1px solid #ededed;
    padding-top: 18px;
    .news-item {
      width: 100%;
      height: 76px;
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      .news-left {
        width: 60px;
        height: 76px;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: flex-end;
        padding-right: 10px;
        border-right: 1px solid #ededed;
        b {
          height: 22px;
          line-height: 22px;
          font-size: 16px;
          font-weight: 700;
          color: #000;
        }
        p {
          height: 20px;
          line-height: 20px;
          font-size: 14px;
          color: #999;
        }
      }
      .news-content {
        width: calc(100% - 78px);
        height: 76px;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        .one-row {
          width: 100%;
          height: 21px;
          line-height: 21px;
          font-size: 15px;
          font-weight: 700;
          margin-bottom: 9px;
          a {
            color: #000;
          }
        }
        .two-row {
          line-height: 23px;
          font-size: 14px;
          color: #666;
        }
      }
      
    }
  }
}
</style>
